import { useEffect, useState } from 'react';

const onIdle = typeof window !== 'undefined' ? window.setTimeout : (cb: () => void) => 0;

// a hook for doing async operations in the background.
export default function useAsync<T>(func: () => Promise<T>): T | null {
    const [state, setState] = useState<T | null>(null);
    const doAsync = async () => {
        setState(await func());
    };
    useEffect(() => {
        const handle = onIdle(() => {
            doAsync();
        });

        return () => {
            if (window.cancelIdleCallback) {
                window.cancelIdleCallback(handle);
            } else {
                window.clearTimeout(handle);
            }
        };
    }, []);

    return state;
}
