import { default as React } from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import animationData from '../../animations/scroll-to-start.json';
import loadable from '@loadable/component';
import Translate from '../Translate';

const Lottie = loadable(() => import('react-lottie'));

const ScrollToStart = () => {
    return (
        <ButtonWrapper>
            <ButtonText><Translate id="scroll" /></ButtonText>
            <LottieContainer>
                <Lottie
                    options={{
                        autoplay: true,
                        loop: true,
                        animationData,
                    }}
                />
            </LottieContainer>
        </ButtonWrapper>
    );
};

export default ScrollToStart;

const ButtonText = styled.span`
    margin-right: 2rem;
    font-weight: bold;
`;

const ButtonWrapper = styled.div`
    position: fixed;
    bottom: 6.8rem;
    right: 5rem;
    display: flex;

    @media screen and (max-width: ${theme.mediaQueries.width.s}), (max-height: ${theme.mediaQueries.height.s}) {
        display: none;
    }
`;

const LottieContainer = styled.div`
    width: 4.8rem;
    height: 2.4rem;
`;
