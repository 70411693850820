const enSolutions = [
    {
        __typename: 'Craft_Solution',
        title: 'Booking system',
        hero: [
            {
                illustration: [
                    {
                        __typename: 'Craft_IllustrationsVolume',
                        url:
                            'https://d37pawv1vxxa2g.cloudfront.net/illustrations/SVG/Reserveringssysteem.svg?mtime=20210331155923&focal=none',
                    },
                ],
            },
        ],
    },
    {
        __typename: 'Craft_Solution',
        title: 'Event platform',
        hero: [
            {
                illustration: [
                    {
                        __typename: 'Craft_IllustrationsVolume',
                        url:
                            'https://d37pawv1vxxa2g.cloudfront.net/illustrations/SVG/Evenement-platform.svg?mtime=20210331155926&focal=none',
                    },
                ],
            },
        ],
    },
    {
        __typename: 'Craft_Solution',
        title: 'Customer Portal',
        hero: [
            {
                illustration: [
                    {
                        __typename: 'Craft_IllustrationsVolume',
                        url:
                            'https://d37pawv1vxxa2g.cloudfront.net/illustrations/SVG/Klantenportaal.svg?mtime=20210331155930&focal=none',
                    },
                ],
            },
        ],
    },
    {
        __typename: 'Craft_Solution',
        title: 'App development',
        hero: [
            {
                illustration: [
                    {
                        __typename: 'Craft_IllustrationsVolume',
                        url:
                            'https://d37pawv1vxxa2g.cloudfront.net/illustrations/SVG/App-ontwikkelen.svg?mtime=20210331155924&focal=none',
                    },
                ],
            },
        ],
    },
    {
        __typename: 'Craft_Solution',
        title: 'A customized platform',
        hero: [
            {
                illustration: [
                    {
                        __typename: 'Craft_IllustrationsVolume',
                        url:
                            'https://d37pawv1vxxa2g.cloudfront.net/illustrations/SVG/Platform-op-maat.svg?mtime=20210331155928&focal=none',
                    },
                ],
            },
        ],
    },
]

export default enSolutions;
