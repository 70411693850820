import { graphql, Link } from 'gatsby';
import React, { Dispatch, FC, SetStateAction, useContext } from 'react';
import styled from 'styled-components';
import { itemWithNumber } from '../../constants/css/itemWithNumber';
import theme from '../../constants/theme';
import { SiteContext } from '../../contexts/SiteContext';
import { SolutionListFragment } from '../../entities/operationResults';
import Title from '../atoms/Title';

export const solutionListFragment = graphql`
    fragment SolutionListFragment on Craft_Solution {
        title
        uri
    }
`;

interface Props {
    data: SolutionListFragment[];
    setSelected: Dispatch<SetStateAction<number>>;
    selected: number;
}

const SolutionsList: FC<Props> = ({ data, setSelected, selected }) => {
    const site = useContext(SiteContext);
    return (
        <List>
            {data.map(({ title, uri }, i) => {
                const isSelected = selected === i;
                return (
                    <Item key={i} selected={isSelected}>
                        {uri ? (
                            <StyledLink
                                key={i}
                                to={site ? `/${site}/${uri}/` : `/${uri}/`}
                                onMouseEnter={() => setSelected(i)}
                                selected={isSelected}
                            >
                                <Title variant={'medium'}>{title}</Title>
                            </StyledLink>
                        ) : (
                            <Title variant={'medium'} onMouseEnter={() => setSelected(i)}>
                                {title}
                            </Title>
                        )}
                    </Item>
                );
            })}
        </List>
    );
};

const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const Item = styled.li<{ selected: boolean }>`
    margin: 2.4rem;
    opacity: ${({ selected }) => (selected ? 1 : 0.4)};
    transition: opacity 0.25s;
    counter-increment: solution;
    ${itemWithNumber('solution')};
`;

const StyledLink = styled(Link)<{ selected: boolean }>`
    position: relative;
    text-decoration: none;
    color: currentColor;
    display: inline-block;

    &:before {
        position: absolute;
        content: '';
        bottom: -2px;
        left: 0;
        right: 0;
        height: 0.3rem;
        background: currentColor;
        opacity: ${({ selected }) => (selected ? 1 : 0)};
        transition: opacity 0.25s;

        @media screen and (max-width: ${theme.mediaQueries.width.m}) {
            height: 0.2rem;
            bottom: -1px;
        }
    }
`;

export default SolutionsList;
