import { graphql } from 'gatsby';
import React, { FC } from 'react';
import theme from '../../constants/theme';
import { SolutionsFragment } from '../../entities/operationResults';
import useCarousel from '../../hooks/useCarousel';
import Grid from '../atoms/Grid';
import { ImgProps } from '../atoms/Img';
import { Section } from '../atoms/Section';
import Title from '../atoms/Title';
import Slideshow from '../molecules/Slideshow';
import SolutionsList from '../molecules/SolutionsList';
import styled from 'styled-components';
import { FixedGrid } from '../atoms/FixedGrid';
import Translate from '../Translate';

export const solutionsFragment = graphql`
    fragment SolutionsFragment on Craft_Solution {
        ...SolutionListFragment

        hero {
            illustration {
                url
            }
            introTitle
        }
    }
`;

interface Props {
    data: SolutionsFragment[];
}

const OurSolutions: FC<Props> = ({ data }) => {
    const [selected, setSelected] = useCarousel(data.length);

    return (
        <Section background={theme.colors.athensGray}>
            <FixedTitleGrid>
                <TitleSection>
                    <Title variant={'huge'}><Translate id="ourSolutions" /></Title>
                </TitleSection>
            </FixedTitleGrid>
            <BodyFixedGrid>
                <BodySection>
                    <SolutionsList setSelected={setSelected} selected={selected} data={data} />
                </BodySection>
            </BodyFixedGrid>
            <Grid marginRight={2}>
                <SlideshowSection>
                    <Slideshow
                        ratio={683 / 1168}
                        selected={selected}
                        images={data
                            .map(({ hero }) => {
                                const heroItem = hero && hero[0];
                                return (
                                    heroItem?.illustration?.[0]?.url &&
                                    ({
                                        alt: heroItem.introTitle,
                                        src: heroItem.illustration[0].url || undefined,
                                    } as Partial<ImgProps>)
                                );
                            })
                            .filter((image): image is Partial<ImgProps> => !!image)}
                    />
                </SlideshowSection>
            </Grid>
        </Section>
    );
};

const FixedTitleGrid = styled(FixedGrid)`
    margin: 0 0 0 20vw;

    @media (max-width: ${theme.mediaQueries.width.xxl}), (max-height: ${theme.mediaQueries.height.xxl}) {
        grid-row: 8 / -1;
        grid-column: 2 / 5;
    }
    @media (max-width: ${theme.mediaQueries.width.xl}), (max-height: ${theme.mediaQueries.height.xl}) {
        grid-row: 7 / -1;
        grid-column: 2 / 6;
    }
    @media (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
        grid-row: 5 / -1;
        grid-column: 2 / 6;
    }
    @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
        grid-row: 4 / -1;
        grid-column: 2 / 6;
    }
    @media screen and (${theme.mediaQueries.vertical}) {
        margin: 0;
    }
`;

const TitleSection = styled.div`
    grid-column: 1 / 6;
    grid-row: 9 / 24;

    @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
        grid-row: 9 / -1;
        grid-column: 1 / 6;
    }
    @media (max-height: 460px) {
        grid-row: 6 / -1;
    }
    @media (max-width: ${theme.mediaQueries.width.s}), (max-height: ${theme.mediaQueries.height.s}) {
        grid-row: 4 / -1;
        grid-column: 1 / 6;
    }

    @media screen and (${theme.mediaQueries.vertical}) {
        grid-column: 1 / 6;
        grid-row: 1 / 16;
    }
`;

const BodyFixedGrid = styled(FixedGrid)`
    margin-right: 10vw;
`;

const BodySection = styled.div`
    grid-row: 9 / 24;
    grid-column: 2 / 6;

    @media (max-width: ${theme.mediaQueries.width.xxl}), (max-height: ${theme.mediaQueries.height.xxl}) {
        grid-row: 8 / -1;
        grid-column: 2 / 6;
    }
    @media (max-width: ${theme.mediaQueries.width.xl}), (max-height: ${theme.mediaQueries.height.xl}) {
        grid-row: 8 / -1;
        grid-column: 2 / 6;
    }
    @media (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
        grid-row: 8 / -1;
        grid-column: 2 / 6;
    }
    @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
        grid-row: 8 / -1;
        grid-column: 2 / 6;
    }
    @media (max-height: 460px) {
        grid-row: 5 / -1;
    }
    @media (max-width: ${theme.mediaQueries.width.s}), (max-height: ${theme.mediaQueries.height.s}) {
        grid-row: 2 / -1;
        grid-column: 2 / 6;
    }
`;

const SlideshowSection = styled.div`
    max-height: 100vh;
    grid-row: 1 / -1;
    align-self: center;

    grid-column: 1 / 9;

    @media (max-width: ${theme.mediaQueries.width.xxl}), (max-height: ${theme.mediaQueries.height.xxl}) {
    }
    @media (max-width: ${theme.mediaQueries.width.xl}), (max-height: ${theme.mediaQueries.height.xl}) {
        grid-column: 1 / 11;
    }
`;

export default OurSolutions;
