import { graphql } from 'gatsby';
import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import {
    CaseTilesFragment,
    CTAFragment,
    HomePageFragment,
    HomePageQuery,
    SolutionsFragment,
} from '../entities/operationResults';
import withDefaultTransition from '../hocs/withDefaultTransition';
import { Section } from './atoms/Section';
import Hero from './organisms/Hero';
import Intro from './organisms/Intro';
import OurSolutions from './organisms/OurSolutions';
import theme from '../constants/theme';
import Trust from './organisms/Trust';
import CaseTiles from './organisms/CaseTiles';
import Footer from './organisms/Footer';
import ContentSection from './molecules/ContentSection';
import Title from './atoms/Title';
import ContentLink from './atoms/ContentLink';
import CTA from './molecules/CTA';
import { WindowLocation } from '@reach/router';
import useAsync from '../hooks/useAsync';
import Meta from './atoms/Meta';
import CmsContent from './atoms/CmsContent';
import { removeTags } from '../utils/removeTags';
import HomeAnimation from './atoms/HomeAnimation';
import styled from 'styled-components';
import { ImgProps } from './atoms/Img';
import enSolutions from '../constants/solutions/enSolutions';
import { SiteContext } from '../contexts/SiteContext';

interface Props {
    data: HomePageQuery;
    location: WindowLocation;
}

export const fragment = graphql`
    fragment HomePageFragment on Craft_Home {
        id
        title
        hero {
            ...HeroFragment
        }
        intro {
            ...IntroFragment
        }
        trust {
            ...TrustFragment
        }
        cases {
            ...CaseTilesFragment
        }
        callToAction {
            ...CTAFragment
        }
        contentSections {
            ...ContentSectionFragment
        }
        directContact {
            ...DirectContactFragment
        }
        seo {
            ...MetaFragment
        }
    }
`;

export const query = graphql`
    query HomePageQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...HomePageFragment
            }
            entries(site: $site, type: Solution) {
                ...SolutionsFragment
            }
        }
    }
`;

const shouldShowAnimation = () =>
    typeof window !== 'undefined' && window.matchMedia(`(min-width: ${theme.mediaQueries.width.s})`).matches;

const HomePage: FC<Props> = ({ data }) => {
    const site = useContext(SiteContext);
    const entry = data.craft.entry as HomePageFragment;
    const hero = entry.hero && entry.hero[0];
    const intro = entry.intro && entry.intro[0];
    const solutions = site !== 'en' ? data.craft && data.craft.entries : enSolutions;
    const ctaList = (entry.callToAction || []) as CTAFragment[];
    const trust = entry.trust && entry.trust[0];
    const contentSections = entry.contentSections && entry.contentSections;
    const footer = entry.directContact && entry.directContact[0];
    const meta = entry.seo && entry.seo[0];

    // if we have differing renders between the client and server side, react gets confused.
    // this is why we're deferring the shouldShowAnimation check with the help of a useEffect
    const [showAnimation, setShowAnimation] = useState<boolean>(false);
    useEffect(() => {
        setShowAnimation(shouldShowAnimation());
    }, []);

    // because useAsync is already deferred, we just call the function directly to avoid race conditions.
    const animationData = useAsync(async () =>
        shouldShowAnimation() ? await import('../animations/home.json') : null
    );

    return (
        <Fragment>
            <Meta
                title={meta && meta.seoTitle ? meta.seoTitle : entry.title}
                description={
                    meta && meta.seoDescription
                        ? meta.seoDescription
                        : intro && intro.text && removeTags(intro.text.content)
                }
                image={meta && meta.seoImage ? meta.seoImage : null}
            />
            <Section>
                {hero && (
                    <Hero
                        data={{ ...hero, illustration: !showAnimation ? hero.illustration : null }}
                        ratio={1369 / 2237}
                        variant="home"
                    >
                        {showAnimation && (
                            <HomeIllustrationContainer ratio={1369 / 2237}>
                                <HomeAnimation data={animationData} />
                            </HomeIllustrationContainer>
                        )}
                    </Hero>
                )}
                {intro && <Intro data={intro} variant="home" />}
            </Section>
            {solutions && <OurSolutions data={solutions as SolutionsFragment[]} />}
            <Section background={theme.colors.shark} fontColor={theme.colors.white}>
                {ctaList && ctaList[0] && <CTA sourceId={entry.id} data={ctaList[0]} variant="caseStart" />}
                {trust && <Trust trust={trust} />}
                <CaseTiles data={(entry.cases || []).filter((tile): tile is CaseTilesFragment => !!tile)} />
                {ctaList[1] && <CTA sourceId={entry.id} align={'right'} data={ctaList[1]} withMargin />}
            </Section>
            <Section paddingLeft={4}>
                {contentSections &&
                    contentSections.length > 0 &&
                    contentSections.map((item, key) => {
                        return !item ? null : (
                            <ContentSection
                                key={key}
                                rightMargin={4}
                                alignImage={key % 2 === 0 || 0 ? 'bottom' : 'top'}
                                image={item && item.image && item.image[0]}
                            >
                                <Title variant="medium">{item.heading}</Title>
                                {item.text && item.text.content && <CmsContent html={item.text.content} />}
                                {item.linkTo && item.linkTo.entry && item.linkTo.entry.uri && item.linkTo.text && (
                                    <ContentLink
                                        to={site ? `/${site}/${item.linkTo.entry.uri}/` : `/${item.linkTo.entry.uri}/`}
                                    >
                                        {item.linkTo.text}
                                    </ContentLink>
                                )}
                            </ContentSection>
                        );
                    })}
            </Section>
            <Section variant={'footer'}>{footer && <Footer entryId={entry.id} data={footer} />}</Section>
        </Fragment>
    );
};

export const HomeIllustrationContainer = styled.div<Partial<ImgProps>>`
    width: ${({ ratio }) => 112 / (ratio || 1) + 'vh'};
    margin: 0 -16vh 0;
    height: 110vh;
    @media (${theme.mediaQueries.vertical}) {
        width: 130%;
        margin: 0 -11%;
        padding: 0;
        height: auto;
    }
`;

export default withDefaultTransition(HomePage);
