import React, { FC, useEffect, useRef, useState } from 'react';
import theme from '../../constants/theme';
import ScrollToStart from './ScrollToStart';
import { Options } from 'react-lottie';
import styled from 'styled-components';
import { useOffsetPosition } from '../../hooks/useOffsetPostion';
import { HomeIllustrationContainer } from '../Home';
import loadable from '@loadable/component';

interface Props {
    data?: Options['animationData'] | null;
}

const Lottie = loadable(() => import('react-lottie'));

const HomeAnimation: FC<Props> = ({ data }) => {
    const [paused, setPaused] = useState(true);
    const lottieContainer = useRef<HTMLDivElement>(null);
    const scrollToStart = useRef<HTMLDivElement>(null);
    let lottieWidth: null | number;

    const onWindowResize = () => {
        lottieWidth = lottieContainer && lottieContainer.current && lottieContainer.current.clientWidth;
        if (window.matchMedia(`(max-width: ${theme.mediaQueries.width.s})`).matches) {
            setPaused(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', onWindowResize, { passive: true });
        onWindowResize();

        return () => {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);

    useOffsetPosition((scroll) => {
        lottieWidth = lottieContainer && lottieContainer.current && lottieContainer.current.clientWidth;

        if (lottieWidth) {
            const width = lottieWidth / 4;
            const scrollPosition = scroll.x;

            if (width <= scrollPosition) {
                setPaused(false);
            }

            if (scrollPosition > 400 || pageXOffset > 400) {
                if (scrollToStart && scrollToStart.current) {
                    scrollToStart.current.style.opacity = '0';
                }
            }
        }
    });

    return (
        <>
            <ScrollToStartWrapper ref={scrollToStart}>
                <ScrollToStart />
            </ScrollToStartWrapper>
            <HomeIllustrationContainer ratio={1369 / 2237} ref={lottieContainer}>
                {Lottie && (
                    <Lottie
                        options={{
                            animationData: data,
                            loop: false,
                            autoplay: false,
                        }}
                        isPaused={paused}
                    />
                )}
            </HomeIllustrationContainer>
        </>
    );
};

const ScrollToStartWrapper = styled.div`
    transition: opacity 0.5s ease-in-out;
`;

export default HomeAnimation;
